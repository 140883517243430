<template>
  <div class="manual c-bg-white">
    <h1 class="ft-sz-30">新手引导</h1>
    <p class="title">如何添加APP下载链接</p>
    <div v-for="(item, index) in list" :key="index">
      <p class="word">{{item.word}}</p>
      <img class="picture" :src="item.imgUrl"/>
    </div>
  </div>
</template>

<script>
export default {
data(){
  return {
    list:[
      {
        word:'1. 账号管理—角色管理添加一个角色，选择用户管理，三要素，渠道推广权限，其他权限根据用户需要',
        imgUrl: require('@/assets/manual/pic1.png')
      },
      {
        word:'2. 帐号列表添加帐号，并绑定上一步创建的角色，这里创建的账号也可以作为子账号使用',
        imgUrl: require('@/assets/manual/pic2.png')
      },
      {
        word:'3. 渠道推广—在推广链接里面添加渠道并绑定上面的帐号',
        imgUrl: require('@/assets/manual/pic3.png')
      }
    ]
  }
}
}
</script>

<style lang="scss" scoped>
.manual{
  padding:40px;
  padding-left: 60px;
  text-align: left;
  color: #000000;
  h1{
    text-align: center;
    font-weight: bold;
    color: #000000;
  }
  .title{
    font-weight: bold;
    font-size: 20px;
  }
  .word{
    margin:20px 0;
  }
  .picture{
    width:80%;
  }
}
</style>
